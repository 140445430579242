import { createGlobalStyle } from "styled-components";
import css from "@styled-system/css";

export const GlobalStyle = createGlobalStyle`
html {
  box-sizing: border-box;

  ${css({
    backgroundColor: "background.primary",
    color: "foreground.primary",
    fontFamily: "body",
    fontSize: [1],
  })}
}
body {
  margin: 0;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
select:focus,
textarea {
  font-size: inherit;
}

.table-wrapper {
  max-width: 1920px;
  overflow: auto;
  width: 100%;
}

table {
  text-align: left;

  th, td {
    ${css({
      padding: [2],
    })}
  }

  th {
    ${css({
      backgroundColor: "background.secondary",
      color: "foreground.secondary",
    })}
  }

  tr:nth-child(2n+1) {
    ${css({
      backgroundColor: "background.disabled"
    })}
  }

  td {
    p {
      margin: 0;
    }
    min-width: 150px;
  }
}
`;
