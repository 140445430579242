import React from "react";
import { Link } from "gatsby";
import * as Styles from "./button.styles";

export const Button = (props) => {
  return (
    <Styles.ButtonWrapper
      onClick={() => props.onClick()}
      variant={props.variant}
    >
      {props.children}
    </Styles.ButtonWrapper>
  );
};

export const LinkButton = (props) => {
  return (
    <Styles.ButtonWrapper
      as={Link}
      title={props.title}
      to={props.to}
      variant={props.variant}
    >
      {props.children}
    </Styles.ButtonWrapper>
  );
};

export const ExternalLinkButton = (props) => {
  return (
    <Styles.ButtonWrapper
      as="a"
      href={props.href}
      rel="noopener noreferrer"
      target="_blank"
      title={props.title}
      variant={props.variant}
    >
      {props.children}
    </Styles.ButtonWrapper>
  );
};
