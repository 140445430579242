import React from "react";
import * as Styles from "./mobile-nav-button.styles";

export const MobileNavButton = (props) => {
  return (
    <Styles.Button onClick={() => props.onClick()}>
      <Styles.BarOne mobileNavOpen={props.mobileNavOpen} />
      <Styles.BarTwo mobileNavOpen={props.mobileNavOpen} />
    </Styles.Button>
  );
};
