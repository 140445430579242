import styled from "styled-components";
import css from "@styled-system/css";

export const Wrapper = styled.div`
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  bottom: 0;
  height: 25vh;
  left: 0;
  position: fixed;
  transition: opacity 1000ms, visibility 1000ms;
  width: 100%;
  z-index: 100;

  ${({ shouldRender }) =>
    css({
      opacity: shouldRender ? "1" : "0",
      visibility: shouldRender ? "visible" : "hidden",
    })}
`;

export const Container = styled.div`
  @keyframes slideIn {
    from {
      bottom: -500px;
    }
    to {
      bottom: 30px;
    }
  }

  animation-name: slideIn;
  animation-duration: 1s;
  position: fixed;
  bottom: 30px;
  left: 50%;
  margin: 0 0 0 -150px;
  width: 300px;

  ${css({
    backgroundColor: "tertiary.background",
    borderRadius: [1],
    boxShadow: "dark",
    padding: [2, 3],
  })}
`;

export const Buttons = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;
