import css from "@styled-system/css";
import styled from "styled-components";

export const Details = styled.div`
  text-align: center;
`;

export const Link = styled.a`
  text-decoration: none;

  ${css({
    color: "foreground.link",
    fontWeight: "bold",
  })}
`;
