import styled from "styled-components";
import css from "@styled-system/css";

export const Polyline = styled.polyline`
  fill: none;
  stroke-width: 12;
  stroke-linecap: round;
  stroke-linejoin: round;

  ${({ colour }) =>
    css({
      stroke: colour || "background.primary",
    })}
`;
