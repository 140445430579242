import React from "react";
import * as Styles from "./arrow.styles";

export const Arrow = (props) => {
  return (
    <svg viewBox="0 0 100 50" {...props}>
      <Styles.Polyline colour={props.colour} points="6,6 50,44 94,6" />
    </svg>
  );
};
