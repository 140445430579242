import styled from "styled-components";
import css from "@styled-system/css";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${({ image, fullscreen }) =>
    css({
      background: `url(${image}) 50% 50%/cover no-repeat fixed`,
      height: fullscreen
        ? ["calc(100vh - 85px)", "calc(100vh - 85px)", "calc(100vh - 145px)"]
        : "400px",
    })}
`;

export const ColourOverlay = styled.div`
  height: 100%;
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 0;
  width: 100%;

  ${css({
    backgroundColor: "background.secondary",
  })}
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 0;
  justify-content: center;
  text-align: center;
  width: 100%;
  z-index: 1;

  ${css({
    color: "foreground.secondary",
  })}
`;

export const ArrowWrapper = styled.div`
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce;
  animation-timing-function: ease;
  bottom: 40px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  width: 40px;
  z-index: 1;

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    30% {
      transform: translateY(-15px);
    }
    50% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }
`;
