import React from "react";
import * as Styles from "./logo.styles";
import { Spacer } from "../../spacer";
import logoImage from "../../../images/wvs-logo.svg";

export const Logo = () => (
  <Styles.WrapperLink title="WVS India" to={"/"}>
    <Styles.Image alt="WVS Logo" src={logoImage} />
    <Spacer width={2} />
    <Styles.Title>India</Styles.Title>
  </Styles.WrapperLink>
);
