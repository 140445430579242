import styled from "styled-components";
import css from "@styled-system/css";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${({ centreColumns }) =>
    css({
      justifyContent: centreColumns ? "space-around" : "space-between",
    })}
`;

export const Column = styled.div`
  display: flex;

  ${({ columnWidth, withBottomMargin }) =>
    css({
      marginBottom: [2, 2, withBottomMargin ? 2 : 0],
      width: ["100%", "48%", columnWidth],
    })}
`;
