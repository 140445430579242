import styled from "styled-components";
import css from "@styled-system/css";
import { Link } from "gatsby";

export const WrapperLink = styled(Link)`
  align-items: center;
  display: flex;
  text-decoration: none;

  ${css({
    color: "foreground.link",
  })}
`;

export const Image = styled.img`
  height: 45px;
  display: inline-block;
`;

export const Title = styled.div`
  ${css({
    fontFamily: "heading",
    fontSize: [2],
  })}
`;
