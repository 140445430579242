import styled from "styled-components";
import css from "@styled-system/css";
import { memo } from "react";

const headerStyles = {
  fontFamily: "heading",
  fontWeight: "semiBold",
  margin: [0],
  padding: [0],
};

export const H1 = memo(
  styled.h1`
    ${css({ ...headerStyles, fontSize: [5] })}
  `
);

export const H2 = memo(
  styled.h2`
    ${css({ ...headerStyles, fontSize: [4] })}
  `
);

export const H3 = memo(
  styled.h3`
    ${css({ ...headerStyles, fontSize: [3] })}
  `
);

export const H4 = memo(
  styled.h4`
    ${css({ ...headerStyles, fontSize: [2] })}
  `
);

export const Text = memo(
  styled.p`
    ${css({ fontSize: [1] })}
  `
);

export const StraplineText = memo(
  styled.p`
    ${css({ fontFamily: "heading", fontSize: [2], fontWeight: "semiBold" })}
  `
);

export const Label = memo(styled.span`
  display: inline-block;
  text-transform: uppercase;

  ${css({ fontFamily: "heading", fontSize: [0], fontWeight: "semiBold" })}
`);

export const ErrorMessage = memo(styled.span`
  ${css({ color: "status.error", fontSize: [0] })}
`);
