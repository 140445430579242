import React from "react";
import * as Styles from "./grid.styles";

export const Grid = ({
  children,
  columns,
  centreColumns,
  withBottomMargin,
}) => {
  let offBy = 0;

  if (children.length % columns) {
    offBy = columns - (children.length % columns);
  }

  const getChildrenWithPadding = (number) => {
    let generated = [];
    for (let i = 0; i < number; i++) {
      generated.push(<></>);
    }

    return children.concat(generated);
  };

  const paddedChildren =
    columns && offBy ? getChildrenWithPadding(offBy) : children;

  const items = paddedChildren.map((item, i) => {
    const columnWidth = `${100 / columns - 2}%`;

    return (
      <Styles.Column
        columnWidth={columnWidth}
        withBottomMargin={withBottomMargin}
        key={i}
      >
        {item}
      </Styles.Column>
    );
  });

  return <Styles.Wrapper centreColumns={centreColumns}>{items}</Styles.Wrapper>;
};
