import React from "react";
import { ThemeProvider } from "styled-components";
import { CookieConsent } from "./cookie-consent";
import { Footer } from "../components/footer";
import { getControlCookie } from "../services/cookies";
import { GlobalStyle } from "./global-style";
import { Header } from "../components/header";
import { Helmet } from "react-helmet";
import { theme } from "../theme";

export const Layout = ({ children }) => {
  const hasGivenCookieConsent = getControlCookie().cookies;
  const gtagPermission = hasGivenCookieConsent ? "granted" : "denied";

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
      {/* Google Tag Manager */}
      <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GOOGLE_ANALYTICS_GTM}`}
        />
      {/* Google Analytics */}
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('consent', 'default', {
            'ad_user_data': '${gtagPermission}',
            'ad_personalization': '${gtagPermission}',
            'ad_storage': '${gtagPermission}',
            'analytics_storage': '${gtagPermission}',
            'wait_for_update': 500,
          });
          gtag('js', new Date());
          gtag('config', '${process.env.GATSBY_GOOGLE_ANALYTICS_GTM}');
        `}
      </script>
      </Helmet>
      <GlobalStyle />
      <Header />
      {children}
      <CookieConsent />
      <Footer />
    </ThemeProvider>
  )
};
