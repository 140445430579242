import styled from "styled-components";
import css from "@styled-system/css";

export const ButtonWrapper = styled.button`
  border: 0;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: background-color 500ms;

  ${({ variant }) =>
    css({
      backgroundColor: variant
        ? "button." + variant + ".background"
        : "button.primary.background",
      borderRadius: [4],
      color: variant
        ? "button." + variant + ".foreground"
        : "button.primary.foreground",
      fontFamily: "heading",
      fontSize: [2],
      fontWeight: "semiBold",
      paddingX: [2],
      paddingY: [1],
      "&:hover": {
        backgroundColor: variant
          ? "button." + variant + ".hover"
          : "button.primary.hover",
      },
    })}
`;
