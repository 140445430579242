import React from "react";
import * as Styles from "./content.styles";
import { MaxWidthContainer } from "../max-width-container";

export const Content = ({ children, image, variant }) => {
  return (
    <Styles.Wrapper image={image} variant={variant}>
      <MaxWidthContainer>
        <Styles.Container>{children}</Styles.Container>
      </MaxWidthContainer>
    </Styles.Wrapper>
  );
};
