const breakpoints = ["768px", "1024px", "1280px"];

const blackTransparent = "rgba(0, 0, 0, 0.4)";
const blue = "#044C60";
const blueDark = "#093A48";
const greyLightest = "#efefef";
// const mediumGrey = "#dedede";
// const darkGrey = "#333333";
const greyLight = "#CCC";
const grey = "#999";
const greyDark = "#333";
const orangeLight = "#FAA24A";
const orange = "#fA8818";
const red = "#CC0000";
const shadowDark = "rgba(0,0,0,0.5)";
const white = "#FFF";

const colors = {
  foreground: {
    primary: greyDark,
    secondary: white,
    tertiary: greyDark,
    link: orange,
    disabled: grey,
  },
  background: {
    primary: white,
    secondary: blue,
    tertiary: orangeLight,
    disabled: greyLight
  },
  border: {
    active: orangeLight,
    inactive: white,
  },
  button: {
    primary: {
      foreground: greyDark,
      background: orangeLight,
      hover: orange,
    },
    secondary: {
      foreground: white,
      background: blue,
      hover: blueDark,
    },
    disabled: {
      foreground: grey,
      background: greyLight,
    },
  },
  status: {
    error: red,
  },
  tab: {
    active: {
      foreground: greyDark,
      background: white,
      border: orange,
    },
    inactive: {
      foreground: greyDark,
      background: greyLightest,
      hover: greyLight,
      border: grey,
      borderHover: greyLightest,
    },
  },
};

const fonts = {
  heading: `'Poppins', sans-serif`,
  body: `'Open Sans', sans-serif`,
};

const fontSizes = ["0.75rem", "1rem", "1.25rem", "1.5rem", "2rem", "2.5rem"];

const fontWeights = {
  regular: `400`,
  semiBold: `600`,
  bold: `700`,
};

const radii = [0, 5, 10, 20, 40];

const shadows = {
  dark: `0 0 30px ${shadowDark}`,
  focus: `inset 0 0 0 2px ${orange}`,
  glow: `0 0 40px ${blackTransparent}`,
  softGlow: `0 0 20px ${blackTransparent}`,
};

const space = [0, 10, 20, 40];

export const theme = {
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  radii,
  shadows,
  space,
};
