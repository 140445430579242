import jsCookie from "js-cookie";
import { cookieNames } from "../constants";

const CONTROL_COOKIE_EXPIRY_DAYS = 365;

const getCookie = (cookieName) => {
  return jsCookie.get(cookieName) ? JSON.parse(jsCookie.get(cookieName)) : undefined;
};

export const getControlCookie = () => {
  const initialControlCookieValue = {
    cookies: false,
    cookieBar: true,
  };

  if (typeof window === `undefined`) {
    return initialControlCookieValue;
  }

  let controlCookie = getCookie(cookieNames.cookieControl);

  if (!controlCookie) {
    jsCookie.set(
      cookieNames.cookieControl,
      JSON.stringify(initialControlCookieValue),
      {
        expires: CONTROL_COOKIE_EXPIRY_DAYS,
      }
    );

    controlCookie = initialControlCookieValue;
  }

  return controlCookie;
};

export const setControlCookie = (value) => {
  if (typeof window === `undefined`) {
    return;
  }

  jsCookie.set(cookieNames.cookieControl, JSON.stringify(value), {
    expires: CONTROL_COOKIE_EXPIRY_DAYS,
  });
};
