import React from "react";
import * as Styles from "./hero.styles";
import { Arrow } from "../icons/arrow";
import { MaxWidthContainer } from "../max-width-container";

export const Hero = (props) => {
  return (
    <Styles.Wrapper image={props.image} fullscreen={props.fullscreen}>
      <Styles.ColourOverlay />
      <Styles.Content>
        <MaxWidthContainer>{props.children}</MaxWidthContainer>
      </Styles.Content>
      {props.fullscreen && (
        <Styles.ArrowWrapper>
          <Arrow />
        </Styles.ArrowWrapper>
      )}
    </Styles.Wrapper>
  );
};
