import React from "react";
import * as Styles from "./nav.styles";
import { MobileNavButton } from "./mobile-nav-button";
import { Spacer } from "../../spacer";
import { LinkButton } from "../../button";

export const Nav = (props) => (
  <>
    <Styles.Wrapper>
      <Styles.NavLink to="/courses" title="Programmes">
        Programmes
      </Styles.NavLink>
      <Spacer width={3} />
      <Styles.NavLink to="/about-us" title="About us">
        About us
      </Styles.NavLink>
      <Spacer width={3} />
      <Styles.NavLink to="/contact" title="Contact">
        Contact
      </Styles.NavLink>
      <Spacer width={3} />
      <LinkButton to="/donate" title="Donate">
        Donate
      </LinkButton>
    </Styles.Wrapper>
    <MobileNavButton
      mobileNavOpen={props.mobileNavOpen}
      onClick={() => props.onClick()}
    />
  </>
);
