import React from "react";
import * as Styles from "./contact-bar.styles";
import { Grid } from "../../grid";
import { Label } from "../../text";
import { MaxWidthContainer } from "../../max-width-container";

export const ContactBar = () => (
  <Styles.Wrapper>
    <MaxWidthContainer>
      <Grid columns={4}>
        <Styles.Column>
          <Styles.Link
            href="mailto:info@wvsindia.org"
            title="info@wvsindia.org"
          >
            <Label>
              Email
              <br />
              info@wvsindia.org
            </Label>
          </Styles.Link>
        </Styles.Column>
        <Styles.Column>
          <Styles.Link
            href="tel:+9109443699376"
            title="WVS India Chairman: Nigel Otter"
          >
            <Label>
              WVS India Chairman: Nigel Otter
              <br />
              +91 (0) 944 36 99 376
            </Label>
          </Styles.Link>
        </Styles.Column>
        <Styles.Column>
          <Label>
            WVS India Reg no.
            <br />
            79/2010
          </Label>
        </Styles.Column>
        <Styles.Column>
          <Label>
            AWBI recognition number <br />
            TN195/2015
          </Label>
        </Styles.Column>
      </Grid>
    </MaxWidthContainer>
  </Styles.Wrapper>
);
