import styled from "styled-components";
import css from "@styled-system/css";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;

  ${(props) =>
    css({
      maxWidth: props.theme.breakpoints[2],
      paddingX: [2, 2, 2, 0],
    })}
`;
