import React from "react";
import * as Styles from "./max-width-container.styles";

export const MaxWidthContainer = ({ children }) => {
  return (
    <Styles.Wrapper>
      <Styles.Container>{children}</Styles.Container>
    </Styles.Wrapper>
  );
};
