import styled from "styled-components";
import css from "@styled-system/css";

export const Wrapper = styled.div`
  ${css({
    backgroundColor: "background.secondary",
    color: "foreground.secondary",
    display: ["none", "none", "block"],
  })}
`;

export const Column = styled.div`
  text-align: center;
  width: 100%;

  ${css({
    paddingY: [1],
  })}
`;

export const Link = styled.a`
  text-decoration: none;

  ${css({
    color: "foreground.secondary",
  })}
`;
