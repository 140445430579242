import React, { useState } from "react";
import * as Styles from "./header.styles";
import { ContactBar } from "./contact-bar";
import { Logo } from "./logo";
import { MaxWidthContainer } from "../max-width-container";
import { MobileNav } from "./mobile-nav";
import { Nav } from "./nav";
import { Spacer } from "../spacer";

export const Header = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <>
      <Styles.HeaderSpacer />
      <Styles.Wrapper>
        <MaxWidthContainer>
          <Styles.Container>
            <Logo />
            <Spacer width={2} />
            <Nav
              mobileNavOpen={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            />
          </Styles.Container>
          <MobileNav mobileNavOpen={mobileNavOpen} />
        </MaxWidthContainer>
        <ContactBar />
      </Styles.Wrapper>
    </>
  );
};
