import React from "react";
import * as Styles from "./footer.styles";
import { Content } from "../content";
import { Spacer } from "../spacer";
import { Text } from "../text";

export const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <Content variant="secondary">
      <Spacer height={3} />
      <Styles.Details>
        <Text>&copy; {year}</Text>
        <Text>
          WVS India, a registered Indian charity (reg no 79/2010), recognised by
          the Animal Welfare Board of India (govt of India).
        </Text>
        <Styles.Link href="/cookies" title="Cookies">
          Cookies
        </Styles.Link>
      </Styles.Details>
    </Content>
  );
};
