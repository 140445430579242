import styled from "styled-components";
import css from "@styled-system/css";

export const HeaderSpacer = styled.div`
  ${css({
    height: ["85px", "85px", "145px"],
  })}
`;

export const Wrapper = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  ${css({
    backgroundColor: "background.primary",
    boxShadow: "glow",
  })}
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  ${css({
    paddingY: [2],
  })}
`;
