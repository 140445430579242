import styled from "styled-components";
import css from "@styled-system/css";

export const Wrapper = styled.div`
  ${({ image, variant }) =>
    css({
      background: `url(${image}) 50% 50%/cover no-repeat`,
      backgroundColor: variant ? "background." + variant : "background.primary",
      color: variant ? "foreground." + variant : "foreground.primary",
    })}
`;

export const Container = styled.div`
  ${css({
    paddingY: [3],
  })}

  p:first-child {
    ${css({
      marginTop: [0],
    })}
  }

  p:last-child {
    ${css({
      marginBottom: [0],
    })}
  }
`;
