import styled from "styled-components";
import css from "@styled-system/css";
import { Link } from "gatsby";

export const Wrapper = styled.nav`
  ${css({
    display: ["none", "none", "flex"],
  })}
`;

export const NavLink = styled(Link).attrs((props) => ({
  activeClassName: "active",
}))`
  align-items: center;
  border-bottom: 5px solid;
  border-top: 5px solid;
  display: flex;
  text-decoration: none;

  ${css({
    color: "foreground.primary",
    borderColor: "border.inactive",
    fontFamily: "heading",
    fontSize: [2],
    fontWeight: "semiBold",
  })}

  &.active {
    ${css({
      borderBottomColor: "border.active",
    })}
  }
`;
