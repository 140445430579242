import React, { useState, useEffect } from "react";
import {
  getControlCookie,
  setControlCookie,
} from "../../services/cookies";
import * as Styles from "./styles";
import { Button } from "../button";
import { H3, Text } from "../text";
import { Spacer } from "../spacer";

export const CookieConsent = () => {
  const [shouldRender, setShouldRender] = useState(false);

  const handleAccept = () => {
    setControlCookie({ cookies: true, cookieBar: false });
    setShouldRender(false);
  };

  const handleDecline = () => {
    setControlCookie({ cookies: false, cookieBar: false });
    setShouldRender(false);
  };

  useEffect(() => {
    const cookie = getControlCookie();
    setShouldRender(cookie.cookieBar);
  }, []);

  return (
    <Styles.Wrapper shouldRender={shouldRender}>
      <Styles.Container>
        <H3>We use cookies</H3>
        <Text>
          We use cookies to ensure that we give you the best experience on our
          website. Would you like to accept all cookies for this site?
        </Text>
        <Text>
          <a title="Tell me more" href="/cookies">
            Tell me more
          </a>
        </Text>
        <Spacer height={2} width={2} />
        <Styles.Buttons>
          <Button
            variant="cancel"
            label="Decline"
            onClick={() => handleDecline()}
          >
            Decline
          </Button>
          <Spacer height={2} width={2} />
          <Button variant="primary" onClick={() => handleAccept()}>
            Accept
          </Button>
        </Styles.Buttons>
      </Styles.Container>
    </Styles.Wrapper>
  );
};
