import styled from "styled-components";
import css from "@styled-system/css";

export const Button = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  height: 40px;

  &:focus {
    outline: none;
  }

  ${css({
    display: ["block", "block", "none"],
    marginRight: "5px",
  })}
`;

export const Bar = styled.div`
  height: 3px;
  margin: 5px 0;
  transition: 300ms;
  width: 25px;

  ${css({
    backgroundColor: "button.primary.background",
    borderRadius: [2],
  })}
`;

export const BarOne = styled(Bar)`
  ${({ mobileNavOpen }) =>
    mobileNavOpen && `transform: translate(0px, 4px) rotate(-45deg);`}
`;

export const BarTwo = styled(Bar)`
  ${({ mobileNavOpen }) =>
    mobileNavOpen && `transform: translate(0px, -4px) rotate(45deg);`}
`;
